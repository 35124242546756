import cookieGet from "../utils/getCookie.util";
import setCookie from "../utils/setCookie.util";

class CookiesPopup {
  constructor() {
    this.init();
  }

  init() {
    const cookiesPopup = document.querySelector(".cookies-popup");
    const cookiesPopupAccept = document.querySelector(".cookies-popup__accept");

    if (cookiesPopup && cookiesPopupAccept) {
      if (!cookieGet("cookies-accepted")) {
        cookiesPopup.classList.add("cookies-popup--visible");
        cookiesPopupAccept.addEventListener("click", () => {
          cookiesPopup.remove();
          setCookie("cookies-accepted", "true", "/", 9999);
        });
      } else {
        cookiesPopup.remove();
      }
    }
  }
}

export default CookiesPopup;
