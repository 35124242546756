import CookiesPopup from './components/CookiesPopup';
import Header from './components/Header';
import LazyLoad from './libs/LazyLoad.lib';
import TextSlideAnimation from './components/TextSlideAnimation';
import AOS from 'aos';
import 'aos/dist/aos.css';

window.addEventListener('DOMContentLoaded', () => {
   AOS.init();
   new Header();
   new CookiesPopup();
   new TextSlideAnimation();
   window.lazyLoad = new LazyLoad();
});
