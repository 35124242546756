import disableScroll from "../utils/disableScroll.util";
import enableScroll from "../utils/enableScroll.util";

class Header {
  private scrollThreshold = 120;
  private root = document.querySelector("header") as HTMLElement;
  constructor() {
    this.init();
  }

  init(): void {
    if (!this.root) return;

    this.addClassOnScroll();
    this.addClassOnHamburgerToggle();
  }

  //add class on window scroll
  addClassOnScroll(): void {
    window.addEventListener("scroll", () => {
      if (window.scrollY > this.scrollThreshold && !this.root.classList.contains("header--scrolled")) {
        this.root.classList.add("header--scrolled");
      } else if (window.scrollY < this.scrollThreshold && this.root.classList.contains("header--scrolled")) {
        this.root.classList.remove("header--scrolled");
      }
    });
  }

  //add class on hamburger button toggle
  addClassOnHamburgerToggle(): void {
    const hamburgerButton = this.root.querySelector(".header__hamburger") as HTMLElement;
    const nav = this.root.querySelector(".header__nav") as HTMLElement;
    if (hamburgerButton) {
      hamburgerButton.addEventListener("click", () => {
        hamburgerButton.classList.contains("header__hamburger--open") ? enableScroll() : disableScroll();
        hamburgerButton.classList.toggle("header__hamburger--open");
        nav.classList.toggle("header__nav--open");
      });
    }
  }
}

export default Header;
