import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class TextSlideAnimation {
   constructor() {
      this.init();
   }

   init() {
      const textSlide = document.querySelectorAll('.caption');
      textSlide.forEach((slide) => {
         const animation = gsap.fromTo(slide, { x: 0 }, { x: 100, duration: 2 });

         ScrollTrigger.create({
            trigger: slide,
            start: '-20% center',
            end: '+=400px',
            scrub: 1,
            animation: animation,
         });
      });
   }
}

export default TextSlideAnimation;
